import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Header() {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');

    useEffect(() => {
        const storedUsername = sessionStorage.getItem('username');
        if (storedUsername) {
            setUsername(storedUsername);
        }
    }, []);

    function handleLogout() {
        sessionStorage.clear();
        navigate("/");
    }

    return (
        <div className="header">
            <div className="header-content">
                <nav className="navbar navbar-expand">
                    <div className="collapse navbar-collapse justify-content-between">
                        <div className="header-left">
                            <div className="dashboard_bar">
                                <div className="d-flex align-items-center justify-content-start">
                                    <p className="mb-0 text-primary me-2 fs-4">Welcome,</p>
                                    <p className="mb-0 text-info fs-4">{username.toUpperCase()}</p>
                                </div>
                            </div>
                        </div>
                        <ul className="navbar-nav header-right">
                            <li className="nav-item d-flex align-items-center">
                                <div className="input-group search-area">
                                    {/* <input type="text" className="form-control" placeholder="Search here..." />
                                    <span className="input-group-text">
                                        <a href="javascript:void(0)"><i className="flaticon-381-search-2"></i></a>
                                    </span> */}

                                </div>
                            </li>
                            <li className="nav-item dropdown notification_dropdown">
                                <a className='nav-link bell me-2' href='/tower'><i className='fas fa-home' title='Home page'></i></a>
                                <a className="nav-link bell dz-theme-mode" href="javascript:void(0);">
                                    <i id="icon-light" className="fas fa-sun" title='Theme'></i>
                                    <i id="icon-dark" className="fas fa-moon" title='Theme'></i>
                                </a>
                                {/* <div className='text-info ms-5 fs-4'><i className='fas fa-user me-2' title='User'></i>{username}</div> */}
                            </li>
                            <li className="nav-item dropdown header-profile">
                                <a className="nav-link" href="javascript:void(0);" role="button" data-bs-toggle="dropdown">
                                    <img src="../../assets/images/24.jpg" width="60" alt="profile" />
                                </a>
                                <div className="dropdown-menu dropdown-menu-end">
                                    <a href="#" className="dropdown-item ai-icon">
                                        <svg id="icon-user1" xmlns="http://www.w3.org/2000/svg" className="text-primary" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                        <span className="ms-2">{username.toUpperCase()} </span>

                                    </a>
                                    <a href="#" onClick={handleLogout} className="dropdown-item ai-icon">
                                        <svg id="icon-logout" xmlns="http://www.w3.org/2000/svg" className="text-danger" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
                                        <span className="ms-2">Logout </span>
                                    </a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    );
}

export default Header;
