import React, { useState, useEffect } from 'react';
import { apiURL } from '../AuthScreens/baseUrl'; // Ensure the base URL is correct
import axios from 'axios';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

function SignIn() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false); // State to manage password visibility
    const [rememberMe, setRememberMe] = useState(true); // Default to checked

    useEffect(() => {
        // Check if credentials are saved in localStorage
        const savedUsername = localStorage.getItem('username');
        const savedPassword = localStorage.getItem('password');
        if (savedUsername && savedPassword) {
            setUsername(savedUsername);
            setPassword(savedPassword);
            setRememberMe(true); // Set checkbox to true if credentials exist
        }
    }, []);

    const handleSignIn = async (e) => {
        e.preventDefault();

        const data = new URLSearchParams();
        data.append('grant_type', '');
        data.append('username', username);
        data.append('password', password);
        data.append('scope', '');
        data.append('client_id', '');
        data.append('client_secret', '');

        try {
            const response = await axios.post(`${apiURL}/token`, data, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'accept': 'application/json',
                },
            });

            const expiryTime = new Date().getTime() + (12 * 60 * 60 * 1000);

            sessionStorage.setItem('accessToken', response.data.access_token);
            sessionStorage.setItem('tokenExpiry', expiryTime);
            sessionStorage.setItem('username', username); // Store the username in sessionStorage

            // Store credentials in localStorage if "Remember me" is checked
            if (rememberMe) {
                localStorage.setItem('username', username);
                localStorage.setItem('password', password);
            } else {
                localStorage.removeItem('username');
                localStorage.removeItem('password');
            }

            toastr.success('Signed in successfully!');
            console.log(response.data);
            window.location.href = '/tower'; 
        } catch (error) {
            if (error.response && error.response.data) {
                toastr.error(`Failed to sign in: ${error.response.data.detail}`);
            } else {
                toastr.error('Failed to sign in. Please check your credentials.');
            }
            console.error('Error during sign-in:', error);
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleRememberMeChange = (e) => {
        setRememberMe(e.target.checked);
    };

    return (
        <div className="fix-wrapper">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-5 col-md-6">
                        <div className="card mb-0 h-auto">
                            <div className="card-body">
                                <div className="text-center mb-4">
                                    <a href="/" className="brand-logo">
                                        <img className="logo-auth" src="../assets/images/infonet_logo 1.png" style={{ maxHeight: '15vh' }} alt="Logo" />
                                    </a>
                                </div>
                                <h4 className="text-center mb-4">Sign in to your account</h4>
                                <form onSubmit={handleSignIn}>
                                    <div className="form-group mb-4">
                                        <label className="form-label" htmlFor="username">Username</label>
                                        <input type="text" className="form-control" placeholder="Enter username" id="username" value={username} onChange={(e) => setUsername(e.target.value)} required />
                                    </div>
                                    <div className="mb-sm-4 mb-3 position-relative">
                                        <label className="form-label" htmlFor="password">Password</label>
                                        <input type={showPassword ? 'text' : 'password'} id="password" className="form-control" placeholder="Enter password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                                        <a className="show-pass eye position-absolute" onClick={togglePasswordVisibility}>
                                            {showPassword ? <i className="fa fa-eye"></i> : <i className="fa fa-eye-slash"></i>}
                                        </a>
                                    </div>
                                    <div className="form-row d-flex flex-wrap justify-content-between mb-4">
                                        <div className="form-group mb-sm-4 mb-1">
                                            <div className="form-check custom-checkbox ms-1">
                                                <input type="checkbox" className="form-check-input" id="basic_checkbox_1" checked={rememberMe} onChange={handleRememberMeChange} />
                                                <label className="form-check-label" htmlFor="basic_checkbox_1">Remember my preference</label>
                                            </div>
                                        </div>
                                        <div className="form-group ms-1">
                                            <a href="/forgotpassword">Forgot Password?</a>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <button type="submit" className="btn btn-primary btn-block">Sign In</button>
                                    </div>
                                </form>
                                <div className="new-account mt-3">
                                    {/* Uncomment and update the line below if you have a sign-up page */}
                                    {/* <p>Don't have an account? <a className="text-primary" href="/signup">Sign up</a></p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SignIn;
