import React from 'react';

function TicketPortal() {
    return (
        <div>
            <div className='container-fluid p-5'>
                <div class="page-titles">
                    <h3 className='text-info m-0 text-center'>Ticket Portal</h3>
                </div>
                <div className='row px-3'>
                    <div className='card col-xl-4'>
                        <div className='card-header'>
                            <h4 className='text-primary'>Ticket Form</h4>
                        </div>
                        <div className='card-body'>
                            <form>
                                <div class="row">
                                    <div class="mb-3 col-md-12">
                                        <label class="form-label">Group ID</label>
                                        <input type="text" class="form-control" placeholder="" value="MC123" disabled />
                                    </div>
                                    <div class="mb-3 col-md-12">
                                        <label class="form-label">Circuit Name</label>
                                        <input type="text" class="form-control" placeholder="" />
                                    </div>
                                    <div class="mb-3 col-md-12">
                                        <label class="form-label">Branch Name</label>
                                        <input type="text" class="form-control" placeholder="" />
                                    </div>
                                    <div class="mb-3 col-md-12">
                                        <label class="form-label">Pin-Code</label>
                                        <input type="text" class="form-control" placeholder="" />
                                    </div>
                                    <div class="mb-3 col-md-12">
                                        <label class="form-label">Description</label>
                                        <textarea type="text" class="form-control" placeholder="" />
                                    </div>
                                </div>
                                <a href type="submit" class="btn btn-primary">Submit</a>
                            </form>
                        </div>
                    </div>
                    <div className='col-xl-1'></div>
                    <div className='card col-xl-7'>
                        <div className='card-header'>
                            <h4 className='text-primary'>Ticket List</h4>
                        </div>
                        <div className='card-body'>
                            <div class="table-responsive" style={{ overflowY: 'auto', maxHeight: '61vh' }}>
                                <table class="table table-hover table-responsive-sm">
                                    <thead>
                                        <tr>
                                            <th>Ticket ID</th>
                                            <th>Ticked Description</th>
                                            <th>Status</th>
                                            <th>Date</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th class="text-black">1</th>
                                            <td>Feasibility check</td>
                                            <td><span class="badge badge-danger light">pending</span>
                                            </td>
                                            <td>20-09-24</td>
                                            <td class="text-end">
                                                <span>
                                                    <button class="btn btn-xs sharp btn-warning me-2" data-bs-toggle="tooltip"
                                                        data-placement="top" title="Edit"><i
                                                            class="fa fa-pencil"></i> </button>
                                                    <button data-bs-toggle="tooltip" className='btn btn-xs sharp btn-danger'
                                                        data-placement="top" title="Delete"><i
                                                            class="fas fa-trash"></i></button>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="text-black">1</th>
                                            <td>Feasibility check</td>
                                            <td><span class="badge badge-danger light">pending</span>
                                            </td>
                                            <td>20-09-24</td>
                                            <td class="text-end">
                                                <span>
                                                    <button class="btn btn-xs sharp btn-warning me-2" data-bs-toggle="tooltip"
                                                        data-placement="top" title="Edit"><i
                                                            class="fa fa-pencil"></i> </button>
                                                    <button data-bs-toggle="tooltip" className='btn btn-xs sharp btn-danger'
                                                        data-placement="top" title="Delete"><i
                                                            class="fas fa-trash"></i></button>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="text-black">2</th>
                                            <td>Due list pending</td>
                                            <td><span class="badge badge-success light">resolved</span>
                                            </td>
                                            <td>19-09-24</td>
                                            <td class="text-end">
                                                <span>
                                                    <button class="btn btn-xs sharp btn-warning me-2" data-bs-toggle="tooltip"
                                                        data-placement="top" title="Edit"><i
                                                            class="fa fa-pencil"></i> </button>
                                                    <button data-bs-toggle="tooltip" className='btn btn-xs sharp btn-danger'
                                                        data-placement="top" title="Delete"><i
                                                            class="fas fa-trash"></i></button>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="text-black">3</th>
                                            <td>Issues with the Due list</td>
                                            <td><span class="badge badge-success light">resolved</span>
                                            </td>
                                            <td>19-09-24</td>
                                            <td class="text-end">
                                                <span>
                                                    <button class="btn btn-xs sharp btn-warning me-2" data-bs-toggle="tooltip"
                                                        data-placement="top" title="Edit"><i
                                                            class="fa fa-pencil"></i> </button>
                                                    <button data-bs-toggle="tooltip" className='btn btn-xs sharp btn-danger'
                                                        data-placement="top" title="Delete"><i
                                                            class="fas fa-trash"></i></button>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="text-black">1</th>
                                            <td>Feasibility check</td>
                                            <td><span class="badge badge-danger light">pending</span>
                                            </td>
                                            <td>20-09-24</td>
                                            <td class="text-end">
                                                <span>
                                                    <button class="btn btn-xs sharp btn-warning me-2" data-bs-toggle="tooltip"
                                                        data-placement="top" title="Edit"><i
                                                            class="fa fa-pencil"></i> </button>
                                                    <button data-bs-toggle="tooltip" className='btn btn-xs sharp btn-danger'
                                                        data-placement="top" title="Delete"><i
                                                            class="fas fa-trash"></i></button>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="text-black">1</th>
                                            <td>Feasibility check</td>
                                            <td><span class="badge badge-danger light">pending</span>
                                            </td>
                                            <td>20-09-24</td>
                                            <td class="text-end">
                                                <span>
                                                    <button class="btn btn-xs sharp btn-warning me-2" data-bs-toggle="tooltip"
                                                        data-placement="top" title="Edit"><i
                                                            class="fa fa-pencil"></i> </button>
                                                    <button data-bs-toggle="tooltip" className='btn btn-xs sharp btn-danger'
                                                        data-placement="top" title="Delete"><i
                                                            class="fas fa-trash"></i></button>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="text-black">3</th>
                                            <td>Issues with the Due list</td>
                                            <td><span class="badge badge-success light">resolved</span>
                                            </td>
                                            <td>19-09-24</td>
                                            <td class="text-end">
                                                <span>
                                                    <button class="btn btn-xs sharp btn-warning me-2" data-bs-toggle="tooltip"
                                                        data-placement="top" title="Edit"><i
                                                            class="fa fa-pencil"></i> </button>
                                                    <button data-bs-toggle="tooltip" className='btn btn-xs sharp btn-danger'
                                                        data-placement="top" title="Delete"><i
                                                            class="fas fa-trash"></i></button>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="text-black">3</th>
                                            <td>Issues with the Due list</td>
                                            <td><span class="badge badge-success light">resolved</span>
                                            </td>
                                            <td>19-09-24</td>
                                            <td class="text-end">
                                                <span>
                                                    <button class="btn btn-xs sharp btn-warning me-2" data-bs-toggle="tooltip"
                                                        data-placement="top" title="Edit"><i
                                                            class="fa fa-pencil"></i> </button>
                                                    <button data-bs-toggle="tooltip" className='btn btn-xs sharp btn-danger'
                                                        data-placement="top" title="Delete"><i
                                                            class="fas fa-trash"></i></button>
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TicketPortal;