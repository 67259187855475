import React from 'react'

function NavBar() {
  return (
    <div class="nav-header">
      <a class="brand-logo">
        <img src="../assets/images/infonet_logo 1.png" style={{ maxHeight: '6vh' }} alt="logo" className="toggle-white" />
      </a>
      <div class="nav-control">
        <div class="hamburger">
          <span class="line"></span><span class="line"></span><span class="line"></span>
        </div>
      </div>
    </div>
  )
}

export default NavBar