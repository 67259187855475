import React from 'react';
import SideBar from '../Layouts/SideBar';
import Header from '../Layouts/Header';
import NavBar from '../Layouts/NavBar';
import Footer from '../Layouts/Footer';

function ServiceDesk() {
    return (
        <div>
            <div id='main-wrapper'>
                <NavBar />
                <Header />
                <SideBar />
                <div className="content-body default-height">
                    <div className="container-fluid">
                        <div class="row page-titles">
                            <ol class="breadcrumb">
                                <h3 className='text-secondary'>Service Desk</h3>
                            </ol>
                        </div>
                        {/* <div className="row">
                            <div className='col-xl'>
                                <div className="card">
                                    <div className="text-center card-body">
                                        <p className="mb-1 fs-5 text-indigo">Muthoot Groups</p>
                                        <h5 className="text-uppercase"><b>Circuit</b></h5>
                                    </div>
                                </div>
                            </div>
                            <div className='col-xl'>
                                <div className="card">
                                    <div className="text-center card-body">
                                        <p className="mb-1 fs-5 text-indigo">Muthoot Corp</p>
                                        <h5 className="text-uppercase"><b>Branch</b></h5>
                                    </div>
                                </div>
                            </div>
                            <div className='col-xl'>
                                <div className="card">
                                    <div className="text-center card-body">
                                        <p className="mb-1 fs-5 text-indigo">MF123</p>
                                        <h5 className="text-uppercase"><b>Group ID</b></h5>
                                    </div>
                                </div>
                            </div>
                            <div className='col-xl'>
                                <div className="card">
                                    <div className="text-center card-body">
                                        <p className="mb-1 fs-5 text-indigo">3</p>
                                        <h5 className="text-uppercase"><b>Total Tickets</b></h5>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className='card'>
                            <div className='card-header'>
                                <h4 className='text-info mx-auto text-center'>Ticket List</h4>
                            </div>
                            <div className='card-header mx-auto text-center'>
                                <ul class="nav nav-pills" role="tablist">
                                    <li class=" nav-item" role="presentation">
                                        <a href="#navpills2-1" class="nav-link active" data-bs-toggle="tab" aria-expanded="true" aria-selected="true" role="tab" tabindex="-1">Pending Tickets</a>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <a href="#navpills2-2" class="nav-link" data-bs-toggle="tab" aria-expanded="false" aria-selected="false" role="tab" tabindex="-1">Resolved Tickets</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="card-body">
                                <div class="tab-content">
                                    <div id="navpills2-1" class="tab-pane active" role="tabpanel">
                                        <div class="table-responsive" style={{ overflowY: 'auto', maxHeight: '48vh' }}>
                                            <table class="table table-hover table-responsive-sm">
                                                <thead>
                                                    <tr>
                                                        <th>Ticket ID</th>
                                                        <th>Ticked Description</th>
                                                        <th>Status</th>
                                                        <th>Raised Date</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th class="text-black">1</th>
                                                        <td>Feasibility check</td>
                                                        <td><span class="badge badge-danger light">pending</span>
                                                        </td>
                                                        <td>20-09-24</td>
                                                        <td>
                                                            <button class="badge badge-success" data-bs-toggle="tooltip"
                                                                data-placement="top" title="Resolve">Resolve </button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th class="text-black">1</th>
                                                        <td>Feasibility check</td>
                                                        <td><span class="badge badge-danger light">pending</span>
                                                        </td>
                                                        <td>20-09-24</td>
                                                        <td>
                                                            <button class="badge badge-success" data-bs-toggle="tooltip"
                                                                data-placement="top" title="Resolve">Resolve </button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th class="text-black">1</th>
                                                        <td>Feasibility check</td>
                                                        <td><span class="badge badge-danger light">pending</span>
                                                        </td>
                                                        <td>20-09-24</td>
                                                        <td>
                                                            <button class="badge badge-success" data-bs-toggle="tooltip"
                                                                data-placement="top" title="Resolve">Resolve </button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th class="text-black">1</th>
                                                        <td>Feasibility check</td>
                                                        <td><span class="badge badge-danger light">pending</span>
                                                        </td>
                                                        <td>20-09-24</td>
                                                        <td>
                                                            <button class="badge badge-success" data-bs-toggle="tooltip"
                                                                data-placement="top" title="Resolve">Resolve </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div id="navpills2-2" class="tab-pane" role="tabpanel">
                                        <table class="table table-hover table-responsive-sm">
                                            <thead>
                                                <tr>
                                                    <th>Ticket ID</th>
                                                    <th>Ticked Description</th>
                                                    <th>Status</th>
                                                    <th>Raised Date</th>
                                                    <th>Resolved Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th class="text-black">2</th>
                                                    <td>Due list pending</td>
                                                    <td><span class="badge badge-success light">resolved</span>
                                                    </td>
                                                    <td>19-09-24</td>
                                                    <td>21-09-24</td>
                                                </tr>
                                                <tr>
                                                    <th class="text-black">2</th>
                                                    <td>Due list pending</td>
                                                    <td><span class="badge badge-success light">resolved</span>
                                                    </td>
                                                    <td>19-09-24</td>
                                                    <td>21-09-24</td>
                                                </tr>
                                                <tr>
                                                    <th class="text-black">3</th>
                                                    <td>Issues with the Due list</td>
                                                    <td><span class="badge badge-success light">resolved</span>
                                                    </td>
                                                    <td>19-09-24</td>
                                                    <td>21-09-24</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div >
    );
}

export default ServiceDesk;