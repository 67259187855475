import './App.css';
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PreLoader from './Components/Layouts/PreLoader';
import SignIn from './Components/AuthScreens/SignIn';
import SignUp from './Components/AuthScreens/SignUp';
import ForgotPassword from './Components/AuthScreens/ForgotPassword';
import TowerDetails from './Components/DashBoard/TowerDetails';
import Grouping from './Components/DashBoard/Grouping';
import ServiceDesk from './Components/DashBoard/ServiceDesk';
import TicketPortal from './Components/DashBoard/TicketPortal';

function App() {

  return (
    <>
      <PreLoader />

      <BrowserRouter>

        <Routes>

          <Route path="/" element={<SignIn />} />
          <Route path='/signup' element={<SignUp />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/tower" element={<TowerDetails />} />
          <Route path="/grouping" element={<Grouping />} />
          <Route path="/servicedesk" element={<ServiceDesk />} />
          <Route path="/portal" element={<TicketPortal />} />

        </Routes>

      </BrowserRouter>

    </>
  );
}

export default App