import React from 'react'

function SideBar() {

	return (
		<div class="dlabnav">
			<div class="dlabnav-scroll">
				<ul class="metismenu" id="menu">
					<li className=''>
						<a type='button' aria-expanded="true" href='/tower'>
							<i class="fas fa-home"></i>
							<span class="nav-text">Dashboard</span>
						</a>
					</li>
					<li className=''>
						<a type='button' aria-expanded="true" href='/grouping'>
							<i class="fas fa-users"></i>
							<span class="nav-text">Grouping</span>
						</a>
					</li>
					<li className=''>
						<a type='button' aria-expanded="true" href='/servicedesk'>
							<i class="fas fa-bar-chart"></i>
							<span class="nav-text">Service Desk</span>
						</a>
					</li>
				</ul>
			</div>
		</div>
	)
}

export default SideBar