import React, { useEffect, useState } from 'react';
import SideBar from '../Layouts/SideBar';
import Header from '../Layouts/Header';
import NavBar from '../Layouts/NavBar';
import Footer from '../Layouts/Footer';
import { apiURL } from '../AuthScreens/baseUrl';

function Grouping() {

    return (
        <div>
            <div id='main-wrapper'>
                <NavBar />
                <Header />
                <SideBar />
                <div className="content-body default-height">
                    <div className="container-fluid">
                        <div class="row page-titles">
                            <ol class="breadcrumb">
                                <h3 className='text-secondary'>Grouping</h3>
                            </ol>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className='col-xl-3'>
                                        <select type="button" className="select form-control wide">
                                            <option className="dropdown-item"> Select a Circuit </option>
                                            <option className="dropdown-item" value=""> Muthoot Group </option>
                                        </select>
                                    </div>
                                    {/* <div className='col-xl-3'>
                                        <select className="select form-control wide" type="button">
                                            <option className="dropdown-item d-none"> Select a Branch </option>
                                            <option className="dropdown-item" value=""> Muthoot Finance </option>
                                            <option className="dropdown-item" value=""> Muthoot Fin-Corp </option>
                                        </select>
                                    </div> */}
                                    <div className='col-xl-3'>
                                        <input type='text' className='form-control' placeholder='xxx xxx - Enter PINCODE' />
                                    </div>
                                    <div className='col-xl-3'>
                                        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#create">Create Group</button>
                                        <div class="modal fade" id="create">
                                            <div class="modal-dialog" role="document">
                                                <div class="modal-content">
                                                    <div class="modal-header align-items-center">
                                                        <h3 class="modal-title text-primary">Group Created</h3>
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal">
                                                        </button>
                                                    </div>
                                                    <div class="modal-body px-5">
                                                        <div>
                                                            <div className='d-flex justify-content-between align-items-center'>
                                                                <h6>Group ID : </h6><p>MC123</p>
                                                            </div>
                                                            <hr />
                                                            <div className='d-flex justify-content-between align-items-center'>
                                                                <h6>Service Desk Link : </h6><a href={'/portal?groupID=MC123'} className='text-info'>https://portal/MC123</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className='col-xl-3'>
                                        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exist">Add to Existing Group</button>
                                        <div class="modal fade" id="exist">
                                            <div class="modal-dialog" role="document">
                                                <div class="modal-content">
                                                    <div class="modal-header align-items-center">
                                                        <h3 class="modal-title text-primary">Existing Group</h3>
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal">
                                                        </button>
                                                    </div>
                                                    <div class="modal-body px-5">
                                                        <div>
                                                            <div className='d-flex justify-content-between align-items-center'>
                                                                <span><b className='text-dark me-2'>Group ID : </b> MF123</span><button className='btn btn-secondary btn-sm'>Add</button>
                                                            </div>
                                                            <hr />
                                                            <div className='d-flex justify-content-between align-items-center'>
                                                                <span><b className='text-dark me-2'>Group ID : </b> MC123</span><button className='btn btn-secondary btn-sm'>Add</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className='card'>
                            <div className='card-body'>
                                <div class="table-responsive">
                                    <table class="table table-responsive-md">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Roll No.</th>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Circuit</th>
                                                <th>Branch</th>
                                                <th>Pin-Code</th>
                                                <th>Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div class="form-check custom-checkbox checkbox-primary check-lg me-3">
                                                        <input type="checkbox" class="form-check-input" id="customCheckBox2" required="" />
                                                        <label class="form-check-label" for="customCheckBox2"></label>
                                                    </div>
                                                </td>
                                                <td><strong>542</strong></td>
                                                <td>Jack</td>
                                                <td>example@example.com	</td>
                                                <td>Muthoot Groups</td>
                                                <td>Muthoot Finance</td>
                                                <td>600-016</td>
                                                <td>01 August 2020</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="form-check custom-checkbox checkbox-primary check-lg me-3">
                                                        <input type="checkbox" class="form-check-input" id="customCheckBox3" required="" />
                                                        <label class="form-check-label" for="customCheckBox3"></label>
                                                    </div>
                                                </td>
                                                <td><strong>542</strong></td>
                                                <td>Robbin</td>
                                                <td>example@example.com	</td>
                                                <td>Muthoot Groups</td>
                                                <td>Muthoot Finance</td>
                                                <td>600-016</td>
                                                <td>01 August 2020</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="form-check custom-checkbox checkbox-primary check-lg me-3">
                                                        <input type="checkbox" class="form-check-input" id="customCheckBox4" required="" />
                                                        <label class="form-check-label" for="customCheckBox4"></label>
                                                    </div>
                                                </td>
                                                <td><strong>542</strong></td>
                                                <td>Johnson</td>
                                                <td>example@example.com	</td>
                                                <td>Muthoot Groups</td>
                                                <td>Muthoot Fin-Corp</td>
                                                <td>600-016</td>
                                                <td>01 August 2020</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div >
    );
}

export default Grouping;