import React from 'react'

function SignUp() {

    return (
        <div>
            <div class="fix-wrapper">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-5 col-md-6">
                            <div class="card mb-0 h-auto">
                                <div class="card-body">
                                    <div class="text-center mb-3">
                                        <a href="#"><img class="logo-auth" src="../assets/images/infonet_logo 1.png" style={{ maxHeight: '15vh' }} alt="" /></a>
                                    </div>
                                    <h4 class="text-center mb-4">Sign up your account</h4>
                                    <form action='/'>
                                        <div class="form-group mb-4">
                                            <label class="form-label" for="username">Username</label>
                                            <input type="text" class="form-control" placeholder="Enter username" id="username" />
                                        </div>
                                        <div class="form-group mb-4">
                                            <label class="form-label" for="email">Email</label>
                                            <input type="email" class="form-control" placeholder="hello@example.com" id="email" />
                                        </div>
                                        <div class="mb-sm-4 mb-3 position-relative">
                                            <label class="form-label" for="dlab-password">Password</label>
                                            <input type="password" id="dlab-password" class="form-control" placeholder="Enter Password" />
                                            <span class="show-pass eye">
                                                <i class="fa fa-eye-slash"></i>
                                                <i class="fa fa-eye"></i>
                                            </span>
                                        </div>
                                        <div class="mb-sm-4 mb-3 position-relative">
                                            <label class="form-label" for="dlab-password">Confirm Password</label>
                                            <input type="password" id="dlab-password" class="form-control" placeholder="Enter Password" />
                                            <span class="show-pass eye">
                                                <i class="fa fa-eye-slash"></i>
                                                <i class="fa fa-eye"></i>
                                            </span>
                                        </div>
                                        <div class="form-row d-flex flex-wrap justify-content-between mb-2">
                                            <div class="form-group mb-sm-4 mb-1">
                                                <div class="form-check custom-checkbox ms-1">
                                                    <input type="checkbox" class="form-check-input" id="basic_checkbox_1" />
                                                    <label class="form-check-label" for="basic_checkbox_1">Remember my preference</label>
                                                </div>
                                            </div>
                                            {/* <div class="form-group ms-2">
                                                <a href="page-forgot-password.html">Forgot Password?</a>
                                            </div> */}
                                        </div>
                                        <div class="text-center">
                                            <button type="submit" class="btn btn-primary btn-block">Register</button>
                                        </div>
                                    </form>
                                    <div class="new-account mt-3">
                                        <p>Don't have an account? <a class="text-primary" href='/'>Sign in</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignUp