// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#map-container {
  position: relative;
}

.floating-details {
  position: relative;
  top: 26px;
  right: 0;
  width: 33%;
  background-color: transparent;
  z-index: 1000;
  height: 70vh; /* Adjust this height as needed */
  overflow-y: auto;
  box-shadow: -2px 0 5px rgba(0,0,0,0.1);
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,QAAQ;EACR,UAAU;EACV,6BAA6B;EAC7B,aAAa;EACb,YAAY,EAAE,iCAAiC;EAC/C,gBAAgB;EAChB,sCAAsC;AACxC","sourcesContent":["#map-container {\n  position: relative;\n}\n\n.floating-details {\n  position: relative;\n  top: 26px;\n  right: 0;\n  width: 33%;\n  background-color: transparent;\n  z-index: 1000;\n  height: 70vh; /* Adjust this height as needed */\n  overflow-y: auto;\n  box-shadow: -2px 0 5px rgba(0,0,0,0.1);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
