import React, { useEffect, useState } from "react";
import SideBar from "../Layouts/SideBar";
import Header from "../Layouts/Header";
import NavBar from "../Layouts/NavBar";
import Footer from "../Layouts/Footer";
import { apiURL } from "../AuthScreens/baseUrl";
import * as XLSX from 'xlsx';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { useNavigate } from 'react-router-dom';

function TowerDetails() {

  const [map, setMap] = useState(null);
  const [circle, setCircle] = useState(null);
  const [towerName, setTowerName] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [showFeasibility, setShowFeasibility] = useState(false);
  const [districts, setDistricts] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectDistrictName, setSelectDistrictName] = useState("");
  const [selectedTower, setSelectedTower] = useState("");
  const [leadsData, setLeadsData] = useState([]);
  const [leadsExcelData, setLeadsExcelData] = useState([]);
  const [selectedTowerName, setSelectedTowerName] = useState("");
  const [activeIndex, setActiveIndex] = useState(null);
  const [customerData, setCustomerData] = useState([]);
  const [customerMarkers, setCustomerMarkers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [filteredData, setFilteredData] = useState({});
  const [circleVisible, setCircleVisible] = useState(false);
  const [markers, setMarkers] = useState({});
  const [towerMarker, setTowerMarker] = useState(null);
  const [startPoint, setStartPoint] = useState(null);
  const [endPoint, setEndPoint] = useState(null);
  const [customersWithinRadius, setCustomersWithinRadius] = React.useState([]);
  const [customerCount, setCustomerCount] = useState(0);
  const [totalLeads, setTotalLeads] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [remainingPercentage, setRemainingPercentage] = useState(100);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const towerIcon = null;
  const customerIcon = "http://maps.google.com/mapfiles/ms/icons/yellow-dot.png";

  useEffect(() => {
    const initMap = () => {
      const mapInstance = new window.google.maps.Map(
        document.getElementById("map"),
        {
          center: { lat: 11.1271, lng: 78.6569 },
          zoom: 7.5,
        }
      );
      setMap(mapInstance);
    };
    if (!map && window.google && window.google.maps) {
      initMap();
    } else {
      window.initMap = initMap;
    }
  }, [map]);

  useEffect(() => {
    fetchTowers();
  }, []);

  useEffect(() => {
    const token = sessionStorage.getItem("accessToken");

    fetch(`${apiURL}/tower_districts`, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.status === 401) {
          toastr.error('Session expired. Please log in again.');
          sessionStorage.removeItem("accessToken");
          navigate('/');
          return Promise.reject('Unauthorized');
        }
        return response.json();
      })
      .then((data) => {
        if (data.status === "success") {
          setDistricts(data.data);
        } else {
          console.error("Error fetching districts:", data.message);
        }
      })
      .catch((error) => {
        if (error !== 'Unauthorized') {
          console.error("Error fetching districts:", error);
        }
      });
  }, [navigate]);

  useEffect(() => {
    if (circle) {
      fetchCustomerData();
    }
  }, [circle]);

  const fetchTowers = () => {
    const token = sessionStorage.getItem("accessToken");
    // const districtName = sessionStorage.getItem("selectedDistrict");
    if (!selectedDistrict) {
      console.error("No district selected");
      return;
    }

    fetch(`${apiURL}/tower_name?district=${selectedDistrict}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok " + response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        console.log("API response data:", data);
        setTowerName(data.data);
        console.log("towerName state after setting:", data.data);
      })
      .catch((error) => {
        console.error("Error fetching tower names:", error);
      });
  };

  const fetchCustomerData = () => {
    customerMarkers.forEach((marker) => {
      if (!isTowerMarker(marker)) {
        marker.setMap(null);
      }
    });
    setCustomerMarkers([]);

    const token = sessionStorage.getItem("accessToken");
    fetch(`${apiURL}/existing_customers?district=${selectedDistrict}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success" && data.data) {
          const filteredCustomers = data.data.filter((customer) => {
            const customerLatLng = new window.google.maps.LatLng(
              parseFloat(customer.latitude),
              parseFloat(customer.longitude)
            );

            return (
              window.google.maps.geometry.spherical.computeDistanceBetween(
                customerLatLng,
                circle.getCenter()
              ) <= circle.getRadius()
            );
          });

          console.log("Customers within radius:", filteredCustomers);
          console.log("Customers within radius:", filteredCustomers.length);

          setCustomersWithinRadius(filteredCustomers);
          setCustomerData(filteredCustomers);
          setCustomerMarkers(filteredCustomers.map((customer) => {
            const markerInstance = new window.google.maps.Marker({
              position: {
                lat: parseFloat(customer.latitude),
                lng: parseFloat(customer.longitude),
              },
              map: map,
              icon: customerIcon,
              title: `Existing Customer Name: ${customer.customer_name}, Purpose: ${customer.purpose},
                                Contact: ${customer.contact}, Status: ${customer.status}`,
            });

            const infoWindowContent = `
                      <div class="info-window">
                          Existing Customer Name :<h6>${customer.customer_name}</h6> Purpose :<h6>${customer.purpose}</h6> 
                          Contact :<h6>${customer.contact}</h6> Status :<h6>${customer.status}</h6>
                      </div> `;

            const infoWindow = new window.google.maps.InfoWindow({
              content: infoWindowContent,
            });

            markerInstance.addListener("click", () => {
              infoWindow.open(map, markerInstance);
            });

            return markerInstance;
          }));
          setCustomerCount(filteredCustomers.length);
        } else {
          console.error(
            "Error fetching customer data:",
            data.message || "Unexpected response"
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching customer data:", error);
      });
  };

  const exportToExcel = (data) => {
    const dataToExport = data.map(customer => ({
      'Name': customer.customer_name,
      'Customer ID': customer.customer_id,
      'Mobile No.': customer.contact,
      'Email ID': customer.email_id,
      'Address': customer.address,
      'Purpose': customer.purpose,
      'Status': customer.status,
      'Latitude': customer.latitude,
      'Longitude': customer.longitude,
    }));

    const ws = XLSX.utils.json_to_sheet(dataToExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Customers_Within_Radius");
    XLSX.writeFile(wb, "Customers_Within_Radius.xlsx");
  };


  const handleExportCustomer = () => {
    exportToExcel(customersWithinRadius);
  };

  const handleCustomerClick = (customerId) => {
    setSelectedCustomer(customerId);
    clearCustomerMarkers();
    const selectedCustomerData = customerData.find(
      (customer) => customer.customer_id === customerId
    );
    if (selectedCustomerData) {
      const customerMarker = new window.google.maps.Marker({
        position: {
          lat: parseFloat(selectedCustomerData.latitude),
          lng: parseFloat(selectedCustomerData.longitude),
        },
        map: map,
        icon: customerIcon,
        title: `Existing Customer Name: ${selectedCustomerData.customer_name}, Purpose: ${selectedCustomerData.purpose},
                Contact: ${selectedCustomerData.contact}, Status: ${selectedCustomerData.status}`,
      });

      const infoWindow = new window.google.maps.InfoWindow({
        content: `<div class="info-window">
            Existing Customer Name :<h6>${selectedCustomerData.customer_name}</h6> Purpose :<h6>${selectedCustomerData.purpose}</h6> 
            Contact :<h6>${selectedCustomerData.contact}</h6> Status :<h6>${selectedCustomerData.status}</h6> 
        </div>`,
      });

      customerMarker.addListener("click", () => {
        infoWindow.open(map, customerMarker);
      });

      setCustomerMarkers([customerMarker]);
      map.setCenter({
        lat: parseFloat(selectedCustomerData.latitude),
        lng: parseFloat(selectedCustomerData.longitude),
      });
      map.setZoom(13);
    }

    if (towerMarker) {
      towerMarker.setMap(map);
    }
    if (circle) {
      circle.setMap(map);
    }
  };

  const isTowerMarker = (marker) => {
    return marker.getIcon() === towerIcon;
  };

  const handleButtonClick = () => {
    handleShowDetails();
    handleTowerUtilizationClick();
  };

  const handleShowDetails = () => {
    setShowDetails(!showDetails);
    setShowFeasibility(false);

    const token = sessionStorage.getItem("accessToken");
    const selectedRange = sessionStorage.getItem("selectedRange");
    const rangeValue = selectedRange ? selectedRange.split(" ")[0] : '';

    if (!rangeValue) {
      toastr.error("Invalid range value", "Error");
      return;
    }

    fetch(`${apiURL}/leads_details?tower_name=${selectedTowerName}&range=${rangeValue}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    })
      .then(response => response.json())
      .then(data => {
        console.log("API Response Data:", data);

        if (data.status_code === 200 && data.data && data.data.results) {
          setLeadsData(data.data);
          setLeadsExcelData(data.data.results);
          setTotalLeads(data.data.total_leads);

          const formattedData = data.data.results.map(item => ({
            Name: item.name,
            Email: item.email,
            Phone: item.phone,
          }));

          console.table(data.data.results, Object.keys(data.data.results[0] || {}));
        } else {
          setLeadsData([]);
          setLeadsExcelData([]);
          toastr.error("Please Select ( District, Range, Tower )", "Error");
        }
      })
      .catch(error => {
        console.error("Error fetching leads data:", error);
        setLeadsData([]);
        setLeadsExcelData([]);
        toastr.error("Error fetching leads data", "Error");
      });

    if (towerMarker) {
      towerMarker.setMap(map);
      towerMarker.setZIndex(window.google.maps.Marker.MAX_ZINDEX + 1);
    }
    if (circle) {
      circle.setMap(map);
    }
  };

  const exportLeadsToExcel = (leadsData) => {
    if (!leadsData || leadsData.length === 0) {
      alert('No data available to export.');
      return;
    }

    const ws = XLSX.utils.json_to_sheet(leadsData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Lead_Count_Data');
    XLSX.writeFile(wb, 'Leads_Count_Data.xlsx');
  };

  const changeMapRange = (range, center) => {
    clearMarkers();
    fetchTowers();
    setShowDetails(false);
    const rangeKm = {
      "1 km": 1.5, "2 km": 2.5, "3 km": 3.5, "4 km": 4.5, "5 km": 5.5,
      "6 km": 6.5, "7 km": 7.5, "8 km": 8.5, "9 km": 9.5, "10 km": 10.5,
    }[range];

    const zoomLevels = {
      "1 km": 14.4, "2 km": 14, "3 km": 13.6, "4 km": 13.2, "5 km": 13,
      "6 km": 12.8, "7 km": 12.6, "8 km": 12.4, "9 km": 12.2, "10 km": 12,
    };

    if (circle) {
      circle.setMap(null);
    }
    const newCircle = new window.google.maps.Circle({
      strokeColor: "red",
      strokeOpacity: 1,
      strokeWeight: 0.5,
      fillColor: "aqua",
      fillOpacity: 0.2,
      map: map,
      center: center || map.getCenter(),
      radius: rangeKm * 1000,
    });
    setCircle(newCircle);
    setCircleVisible(true);

    const newZoom = zoomLevels[range];
    if (newZoom) {
      map.setZoom(newZoom);
    }

    const styles = [
      {
        featureType: "all",
        stylers: [
          { visibility: "simplified" },
          { saturation: 30 },
          { lightness: 1 },
          { gamma: 1 },
          { invert_lightness: false },
        ],
      },
    ];
    const styledMap = new window.google.maps.StyledMapType(styles, {
      name: "Blur Map",
    });
    map.mapTypes.set("blur_map", styledMap);
    map.setMapTypeId("blur_map");
  };

  const addMarker = (
    mapInstance, latitude, longitude,
    icon, type,
    name = `Tower Pointer, Range: ${selectedTower}`,
    phoneNumber,
    address
  ) => {
    const iconSize = type === "tower" ? new window.google.maps.Size(150, 150) : undefined;

    const markerIcon = { url: icon, size: iconSize, scaledSize: iconSize };

    const markerInstance = new window.google.maps.Marker({
      position: { lat: latitude, lng: longitude },
      map: mapInstance, icon: markerIcon, title: name,
    });

    let content = `<div class="info-window">`;
    content += `Pointer Name :<h6> ${name}</h6>`;
    if (phoneNumber) content += `Phone Number :<h6> ${phoneNumber}</h6>`;
    if (address) content += `Address :<h6> ${address}</h6>`;
    content += `</div>`;

    const infoWindow = new window.google.maps.InfoWindow({
      content: content,
    });

    markerInstance.addListener("click", () => {
      infoWindow.open({
        anchor: markerInstance, map: mapInstance, shouldFocus: false,
      });
    });

    setMarkers((prev) => ({
      ...prev,
      [type]: prev[type] ? [...prev[type], markerInstance] : [markerInstance],
    }));

    return markerInstance;
  };


  const handleLeadClick = (latitude, longitude) => {
    if (map) {
      const selectedRange = sessionStorage.getItem("selectedRange");
      map.setCenter({ lat: latitude, lng: longitude });
      if (towerMarker) {
        towerMarker.setMap(null);
      }
      const newTowerMarker = addMarker(map, latitude, longitude, towerIcon);
      setTowerMarker(newTowerMarker);
      changeMapRange(selectedRange, { lat: latitude, lng: longitude });
    }
  };

  const handleSelectChange = (event) => {
    setShowDetails(false);
    const value = event.target.value;
    if (value) {
      const [latitude, longitude] = value.split(",").map(Number);
      handleLeadClick(latitude, longitude);
    }
    setSelectedTower(event.target.value);
    const tower = towerName.find(
      (tower) => `${tower.latitude},${tower.longitude}` === event.target.value
    );
    if (tower) {
      setSelectedTowerName(tower.tower_name);
    }
    setCustomerData([]);
    clearCustomerMarkers();
  };

  const handleClickLead = (latitude, longitude, leadName) => {
    clearMarkers();
    addMarker(map, latitude, longitude,
      "http://maps.google.com/mapfiles/ms/icons/purple-dot.png",
      "lead", leadName
    );
    setCircleVisible(true);
    map.setCenter({ lat: latitude, lng: longitude });
    map.setZoom(13);

    if (towerMarker) {
      towerMarker.setMap(map);
    }
    if (circle) {
      circle.setMap(map);
    }
  };

  const handleShowTypeData = (index, leadType) => {
    setActiveIndex(activeIndex === index ? null : index);
    const token = sessionStorage.getItem("accessToken");
    // const selectedTowerName = sessionStorage.getItem("selectedTowerName");
    const selectedRange = sessionStorage.getItem("selectedRange");
    const rangeValue = selectedRange.split(" ")[0];
    fetch(
      `${apiURL}/leads_type?tower_name=${selectedTowerName}&range=${rangeValue}&type=${leadType}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
        method: "POST",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("API Response Leads Data:", data);
        if (data.status_code === 200 && data.data) {
          setFilteredData((prev) => ({
            ...prev,
            [index]: data.data.results,
          }));
          clearMarkers();
          data.data.results.forEach((result) => {
            console.table(data.data.results, Object.keys(data.data.results[0] || {}));
            addMarker(
              map,
              parseFloat(result.latitude),
              parseFloat(result.longitude),
              getIconByType(towerIcon),
              getIconByType(leadType),
              result.name,
              result.phone_number,
              result.address
            );
          });

          if (!circleVisible) {
            changeMapRange(map, "2 km", {
              lat: parseFloat(selectedTower.split(",")[0]),
              lng: parseFloat(selectedTower.split(",")[1]),
            });
            setCircleVisible(true);
          }
          if (towerMarker) {
            towerMarker.setMap(map);
          }

        }
      })
      .catch((error) => console.error("Error fetching filtered data:", error));
  };

  const handleExportExcel = () => {
    const dataToExport = filteredData[activeIndex] || [];
    exportLeadsDetailsToExcel(dataToExport);
  };

  const exportLeadsDetailsToExcel = (data) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Leads_Type_Data');

    XLSX.writeFile(wb, 'Leads_Type_Data.xlsx');
  };

  const getIconByType = (type) => {
    switch (type) {
      default:
        return "http://maps.google.com/mapfiles/ms/icons/purple-dot.png";
    }
  };

  const clearMarkers = () => {
    Object.keys(markers).forEach((type) => {
      markers[type].forEach((marker) => marker.setMap(null));
    });
  };

  useEffect(() => {
    if (towerMarker) {
      towerMarker.setMap(map);
    }
    if (circle) {
      circle.setMap(map);
    }
  }, [towerMarker, circle, map]);

  const clearCustomerMarkers = () => {
    customerMarkers.forEach((marker) => {
      if (!isTowerMarker(marker)) {
        marker.setMap(null);
      }
    });
    setCustomerMarkers([]);
  };

  const changeMap = (location) => {
    setShowDetails(false);
    const selectedLocation = districts.find(
      (district) => district.district.toLowerCase() === location
    );
    if (map && selectedLocation) {
      var districtName = selectedLocation.district;
      setSelectDistrictName(districtName);
      console.log(districtName);
      map.setCenter({
        lat: parseFloat(selectedLocation.latitude),
        lng: parseFloat(selectedLocation.longitude),
      });
      map.setZoom(13);
    }
  };

  useEffect(() => {
    const calculatedPercentage = calculatePercentage();
    setPercentage(calculatedPercentage);
  }, [customerCount, totalLeads]);

  const calculatePercentage = () => {
    if (totalLeads === 0) {
      return 0;
    }
    return (customerCount / totalLeads) * 100;
  };

  const handleTowerUtilizationClick = () => {
    const calculatedPercentage = calculatePercentage();
    setPercentage(calculatedPercentage);
  };

  useEffect(() => {
    const calculatedPercentage = calculatePercentage();
    setPercentage(calculatedPercentage);
    setRemainingPercentage(100 - calculatedPercentage);
  }, [customerCount, totalLeads]);

  const handleShowFeasibility = () => {
    // setShowFeasibility(!showFeasibility);
    // setShowDetails(false);
    toastr.info('Feature Coming Soon!', {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  const filteredCustomerData = customerData.filter((customer) =>
    customer.contact.includes(searchTerm)
  );

  const handleAccordionClick = (index, type) => {
    setActiveIndex(index === activeIndex ? null : index);
    handleShowTypeData(index, type);
  };

  return (
    <div>
      <div id="main-wrapper">
        <NavBar />
        <Header />
        <SideBar />
        <div className="content-body default-height">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-xl-3 mb-2" onClick={fetchTowers}>
                        <select type="button" className="select form-control wide"
                          onChange={(e) => {
                            const selectedValue = e.target.value;
                            setSelectedDistrict(selectedValue);
                            changeMap(selectedValue.toLowerCase());
                            // sessionStorage.setItem("selectedDistrict", selectedValue);
                          }}
                          value={selectedDistrict} >
                          <option value="">Select District</option>
                          {districts.map((district, index) => (
                            <option key={index} value={district.district}>
                              {district.district}
                            </option>
                          ))}
                        </select>
                        {districts.length > 0 ? (
                          <span className="ms-2">
                            <b className="text-primary">District Count :</b>{" "}
                            <b className="text-info">{districts.length}</b>
                          </span>
                        ) : (
                          <span className="text-warning">Loading districts...</span>
                        )}
                      </div>

                      <div className="col-xl-2 mb-2"
                        onClick={(e) => {
                          const selectedValue = e.target.value;
                          setSelectedTower(selectedValue);
                          handleSelectChange(e);
                          // const selectedTowerObj = towerName.find((tower) => `${tower.latitude},${tower.longitude}` === selectedValue);
                          // sessionStorage.setItem("selectedTower", selectedValue);
                          // if (selectedTowerObj) { sessionStorage.setItem("selectedTowerName", selectedTowerObj.tower_name); }
                        }} >
                        <select className="select form-control wide" type="button"
                          onChange={(e) => {
                            changeMapRange();
                            const selectedRange = e.target.value;
                            sessionStorage.setItem("selectedRange", selectedRange);
                          }} >

                          <option className="dropdown-item d-none"> Choose Range </option>
                          <option className="dropdown-item" value="1 km"> Range 1 km </option>
                          <option className="dropdown-item" value="2 km"> Range 2 km </option>
                          <option className="dropdown-item" value="3 km"> Range 3 km </option>
                          <option className="dropdown-item" value="4 km"> Range 4 km </option>
                          <option className="dropdown-item" value="5 km"> Range 5 km </option>
                          <option className="dropdown-item" value="6 km"> Range 6 km </option>
                          <option className="dropdown-item" value="7 km"> Range 7 km </option>
                          <option className="dropdown-item" value="8 km"> Range 8 km </option>
                          <option className="dropdown-item" value="9 km"> Range 9 km </option>
                          <option className="dropdown-item" value="10 km"> Range 10 km </option>
                        </select>
                        <span className="ms-2">
                          <b className="text-primary">Tower Range</b>
                        </span>
                      </div>

                      <div className="col-xl-3 mb-2" onClick={fetchTowers}>
                        <select type="button" className="select form-control wide"
                          onChange={(e) => {
                            const selectedValue = e.target.value;
                            setSelectedTower(selectedValue);
                            handleSelectChange(e);
                            // const selectedTowerObj = towerName.find(
                            //   (tower) => `${tower.latitude},${tower.longitude}` === selectedValue);
                            // sessionStorage.setItem("selectedTower", selectedValue);
                            // if (selectedTowerObj) { sessionStorage.setItem("selectedTowerName", selectedTowerObj.tower_name); }
                          }}
                          value={selectedTower}>

                          <option value="">Choose a Tower</option>
                          {towerName.map((tower, index) => (
                            <option key={index} value={`${tower.latitude},${tower.longitude}`} >
                              {tower.tower_name}
                            </option>
                          ))}
                        </select>
                        {towerName.length > 0 ? (
                          <span className="ms-2">
                            <b className="text-primary">Tower Count :</b>{" "}
                            <b className="text-info">{towerName.length}</b>
                          </span>
                        ) : (
                          <span className="text-danger">No towers for selected district</span>
                        )}
                      </div>

                      <div className="col-xl-2 mb-2">
                        <a className="btn btn-primary light" onClick={handleButtonClick} >
                          <i className="fas fa-user me-3"></i>Show Leads</a>
                      </div>
                      <div className="col-xl-2 mb-2">
                        <a className="btn btn-primary light" onClick={handleShowFeasibility}>
                          <i className="fas fa-map me-3"></i> Feasibility</a>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className={`col-12 ${showDetails || showFeasibility ? "col-lg-7" : ""} `} id="map-container" >
                        <div id="map" style={{ width: "100%", height: "70vh", marginTop: "3vh", }}></div>
                      </div>
                      {showDetails && (
                        <div className="col-xl-5 mt-4 dlab-scroll" style={{ maxHeight: "70vh" }}>
                          <div className="row px-3 mt-1">
                            <div className="col-12">
                              <div className="accordion accordion-primary-solid" id="accordion-two">
                                <div className="accordion-item">
                                  <h2 className="accordion-header">
                                    <button onClick={fetchCustomerData} className="accordion-button d-flex justify-content-between"
                                      type="button" data-bs-toggle="collapse" data-bs-target="#bordered_collapseOne" >
                                      <span className="fw-bold"> EXISTING CUSTOMER </span>
                                      <span className="badge-sm text-dark badge-warning fw-bold me-4">
                                        <i className="fas fa-map-pin me-2"></i>
                                        {customerData.length}
                                      </span>
                                    </button>
                                  </h2>
                                  <div id="bordered_collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordion-two" >
                                    <div className="accordion-body p-0">
                                      {filteredCustomerData.length > 0 ? (
                                        <div className="table-responsive" style={{ height: "28vh", overflow: "auto" }}>
                                          <table className="table table-bordered">
                                            <thead>
                                              <tr>
                                                <th><input type="text" className="form-control" placeholder="Search by contact number"
                                                  value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} /></th>
                                                <th><a className="btn btn-sm btn-success light" onClick={handleExportCustomer} >
                                                  <i className="fas fa-download me-2"></i>Export{" "}Excel
                                                </a></th>
                                              </tr>
                                            </thead>
                                            <thead>
                                              <tr>
                                                <th>Customer Name</th>
                                                <th>Contact</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {filteredCustomerData.map((customer) => (
                                                <tr key={customer.customer_id}>
                                                  <td className="btn-primary" onClick={() => handleCustomerClick(customer.customer_id)}>
                                                    <a type="button">{customer.customer_name}</a>
                                                  </td>
                                                  <td>{customer.contact}</td>
                                                </tr>
                                              ))}
                                            </tbody>
                                          </table>
                                        </div>
                                      ) : (
                                        <p className="p-2"> No customers found </p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="email-left-box">
                            <div className=" mt-sm-0">
                              <div className="rounded">
                                <div className="intro-title">
                                  <h5 className="text-info">Tower Details</h5>
                                </div>
                                <div className="list-group-item">
                                  <div className="d-flex align-items-center justify-content-start mb-3">
                                    <a className="text-primary me-2 fw-bold fs-4"> TOWER NAME </a>
                                    <p className="mb-0 mx-2 fw-bold fs-5">:</p>
                                    <p className="mb-0 text-info fw-bold fs-4">{selectedTowerName}</p>
                                  </div>
                                  <div className="row">
                                    <div className="col-xl-12 mb-3">
                                      <div className="d-flex align-items-center justify-content-start">
                                        <a className="text-primary me-2 fw-bold"> TOWER OPPORTUNITIES </a>
                                        <p className="mb-0 mx-2 fw-bold fs-5">:</p>
                                        <p className="mb-0 text-secondary fw-bold fs-5">{remainingPercentage.toFixed(2)}%</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-xl-12 mb-3">
                                      <div className="d-flex align-items-center justify-content-start">
                                        <a className="text-primary me-2 fw-bold"> TOWER UTILIZATION </a>
                                        <p className="mb-0 mx-2 fw-bold fs-5">:</p>
                                        <p className="mb-0 text-secondary fw-bold fs-5">{percentage.toFixed(2)}%</p>
                                      </div>
                                    </div>
                                  </div>
                                  {/* <div className="row">
                                    <div className="col-xl-6 mb-2">
                                      <a className="btn btn-sm btn-primary light" onClick={handleExportCustomer}>
                                        <i className="fas fa-download me-3"></i>Export Customer Details</a>
                                    </div>
                                    <div className="col-xl-6 mb-2">
                                      <a className="btn btn-sm btn-primary light" onClick={() => exportLeadsToExcel(leadsExcelData)}>
                                        <i className="fas fa-download me-3"></i>Export Leads Type & Count</a>
                                    </div>
                                  </div> */}
                                  <div className="email-left-box rounded m-0 p-0">
                                    <div className="intro-title d-flex justify-content-between">
                                      <h5 className="text-info"> Total Leads : </h5>
                                      <span className="badge badge-danger"> {totalLeads} </span>
                                    </div>
                                  </div>
                                  <div>
                                    <div className="accordion accordion-primary-solid" id="accordion-one">
                                      {leadsExcelData.map((lead, index) => (
                                        <div className="accordion-item" key={index}>
                                          <h2 className="accordion-header">
                                            <button
                                              className="accordion-button d-flex justify-content-between"
                                              onClick={() => handleAccordionClick(index, lead.type)}>
                                              <span className="fw-bold"> {lead.type.toUpperCase()} </span>
                                              <span className="badge-sm badge-secondary fw-bold me-4">
                                                {lead.counts_by_type}
                                              </span>
                                            </button>
                                          </h2>
                                          {activeIndex === index && (
                                            <div className="accordion-body p-0 m-0">
                                              {filteredData[index] && filteredData[index].length > 0 ? (
                                                <div className="table-responsive" style={{ maxHeight: "30vh", overflow: "auto" }}>
                                                  <table className="table table-bordered">
                                                    <thead>
                                                      <tr>
                                                        <th><input type="text" className="form-control" placeholder="Search by contact number"
                                                          value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} /></th>
                                                        <th><a className="btn btn-sm btn-success light" onClick={handleExportExcel} >
                                                          <i className="fas fa-download me-2"></i>Export{" "}Excel
                                                        </a></th>
                                                      </tr>
                                                    </thead>
                                                    <thead>
                                                      <tr>
                                                        <th>Name</th>
                                                        <th>Contact</th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {filteredData[index]
                                                        .filter((data) =>
                                                          data.phone_number
                                                            .toLowerCase()
                                                            .includes(searchTerm.toLowerCase()))
                                                        .map((data, idx) => (
                                                          <tr key={idx}>
                                                            <td className="btn-primary"
                                                              onClick={() => handleClickLead(
                                                                parseFloat(data.latitude),
                                                                parseFloat(data.longitude),
                                                                data.name)}>
                                                              <a type="button">{data.name}</a>
                                                            </td>
                                                            <td>{data.phone_number}</td>
                                                          </tr>
                                                        ))}
                                                    </tbody>
                                                  </table>
                                                </div>
                                              ) : (
                                                <p className="p-2">
                                                  No data available for {lead.type}
                                                </p>
                                              )}
                                            </div>
                                          )}
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {showFeasibility && (
                        <div className="col-xl-5 mt-4 dlab-scroll height720">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="email-left-box">
                                <div className=" mt-sm-0">
                                  <div className="rounded">
                                    <div className="intro-title">
                                      <h5 className="text-info">Check Feasibility</h5>
                                    </div>
                                    <div className="list-group-item">
                                      <div className='my-4'>
                                        <h6 className="text-primary">Select Start Point:</h6>
                                        <select
                                          className="select form-control wide"
                                          onClick={fetchTowers}
                                          // onChange={handleSelectFeasibility}
                                          value={startPoint ? `${startPoint.lat},${startPoint.lng}` : ''}
                                        >
                                          <option value="" disabled>Choose a Start Point</option>
                                          {towerName.map((tower, index) => (
                                            <option key={index} value={`${tower.latitude},${tower.longitude}`}>{tower.tower_name}</option>
                                          ))}
                                        </select>
                                      </div>
                                      <div className='my-4'>
                                        <h6 className="text-primary">Select End Point:</h6>
                                        <select
                                          className="select form-control wide"
                                          onClick={fetchTowers}
                                          // onChange={handleSelectFeasibility}
                                          value={endPoint ? `${endPoint.lat},${endPoint.lng}` : ''}
                                        >
                                          <option value="" disabled>Choose an End Point</option>
                                          {towerName.map((tower, index) => (
                                            <option key={index} value={`${tower.latitude},${tower.longitude}`}>{tower.tower_name}</option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default TowerDetails;