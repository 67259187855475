import React from 'react'

function Footer() {
  return (
    <div class="footer">
      <div class="copyright">
        <p>Copyright © Designed &amp; Developed by <a href="https://datamoo.ai/" target="_blank">DataMoo AI</a> 2024</p>
      </div>
    </div>
  )
}

export default Footer