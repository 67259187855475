import React, { useState } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { apiURL } from './baseUrl';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [userId, setUserId] = useState(null);
    const [otpSent, setOtpSent] = useState(false);

    const handleEmailSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${apiURL}/forget_password?email=${email}`);
            if (response.data.OTP && response.data.user_id) {
                setOtp(response.data.OTP);
                setUserId(response.data.user_id);
                toastr.success(`OTP sent successfully: ${response.data.OTP}`);
                setOtpSent(true);
            } else {
                toastr.info(response.data.detail || 'OTP sent successfully. Please check your email.');
                setOtpSent(true);
            }
        } catch (error) {
            console.error(error);
            if (error.response && error.response.data && error.response.data.detail) {
                toastr.error(`Error: ${error.response.data.detail}`);
            } else {
                toastr.error('Error sending OTP. Please try again.');
            }
        }
    };

    const handlePasswordReset = async (e) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            toastr.warning('Passwords do not match.');
            return;
        }
        try {
            const response = await axios.post(`http://165.232.187.1:8000/reset_password`, null, {
                params: {
                    email,
                    entered_otp: otp,
                    new_password: newPassword,
                    confirm_password: confirmPassword,
                },
            });
            toastr.success(response.data.detail || 'Password reset successfully.');
            window.location.href = '/'; 
        } catch (error) {
            console.error(error);
            if (error.response && error.response.data && error.response.data.detail) {
                toastr.error(`Error: ${error.response.data.detail}`);
            } else {
                toastr.error('Error resetting password. Please try again.');
            }
        }
    };

    return (
        <div className="fix-wrapper">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-5 col-md-6">
                        <div className="card mb-0 h-auto">
                            <div className="card-body">
                                <div className="text-center mb-3">
                                    <a href="/"><img className="logo-auth" src="../assets/images/infonet_logo 1.png" style={{ maxHeight: '15vh' }} alt=""/></a>
                                </div>
                                <h4 className="text-center mb-4">Forgot Password</h4>
                                {!otpSent ? (
                                    <form onSubmit={handleEmailSubmit}>
                                        <div className="form-group mb-4">
                                            <label className="form-label" htmlFor="email">Email</label>
                                            <input type="email" className="form-control" placeholder="hello@example.com" id="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                                        </div>
                                        <div className="text-center">
                                            <button type="submit" className="btn btn-primary btn-block">SUBMIT</button>
                                        </div>
                                    </form>
                                ) : (
                                    <form onSubmit={handlePasswordReset}>
                                        <div className="form-group mb-4">
                                            <label className="form-label" htmlFor="otp">OTP</label>
                                            <input type="text" className="form-control" placeholder="Enter OTP" id="otp" value={otp} onChange={(e) => setOtp(e.target.value)} required />
                                        </div>
                                        <div className="form-group mb-4">
                                            <label className="form-label" htmlFor="newPassword">New Password</label>
                                            <input type="password" className="form-control" placeholder="New Password" id="newPassword" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required />
                                        </div>
                                        <div className="form-group mb-4">
                                            <label className="form-label" htmlFor="confirmPassword">Confirm Password</label>
                                            <input type="password" className="form-control" placeholder="Confirm Password" id="confirmPassword" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />
                                        </div>
                                        <div className="text-center">
                                            <button type="submit" className="btn btn-primary btn-block">RESET PASSWORD</button>
                                        </div>
                                    </form>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
